<template>
  <div class="main">
    <div>
      <router-link
      :to="{ name: 'PhoneNumbers' }"
      class="back-link mt-3 ml-0 pl-1 pl-md-3"
    >
        <v-icon size="24px">
          navigate_before
        </v-icon>
        <span class="velvet-grey">
          Phone Numbers
        </span>
      </router-link>
    </div>
    <div class="main__title mb-5 py-2 px-2 py-sm-4 px-sm-4">
      <span :class="isDesktop ? 'fz-28': 'text-h5 ml-1'" class="dark-cyanBlue font-weight-semi-bold base-family">Available phone numbers</span>
    </div>
    <div class="main__content d-flex flex-column flex-sm-row">
      <div class="main__content__left mr-sm-5">
        <div class="white py-2 px-2 py-sm-4 px-sm-4 elevation-1">
          <!-- <span class="fz-20">Choose number type</span> //FONIO-2382
          <div class="radio-group"> -->
            <!-- <div v-if="!isFreeAccount" :class="{ 'is-checked': numberType === 'is_free' }" class="radio" @click="changeNumberType('is_free')">
              <div class="radio-btn"></div>
              <span>Free</span>
            </div> -->
            <!-- <div :class="{ 'is-checked': numberType === 'long_code' }" class="radio" @click="changeNumberType('long_code')">
              <div class="radio-btn"></div>
              <span>Longcode</span>
            </div>
          </div> -->
          <!-- <v-radio-group :value="numberType" row @change="changeNumberType">
            <v-radio label="Longcode" value="long_code" />
            <v-radio label="Free" value="is_free" />
          </v-radio-group> -->
          <v-col v-if="isFreeAccount">
            <v-radio-group v-model="isFreeNumber">
              <v-radio label="Free Number from Limited Coverage" :value="false" />
              <v-radio label="$1 / month thoughtout entire US and Canada" :value="true" />
            </v-radio-group>
          </v-col>
          <v-col>
            <div class="d-flex flex-column flex-sm-row">
              <v-col class="pa-0 mr-4">
                <div class="main__content__left__dropdown">
                  <span class="dark-cyanBlue base-family">State</span>
                  <v-select
                    v-model="state"
                    :items="stateOptions"
                    item-text="label"
                    item-value="value"
                    label="Choose state"
                    dense
                    solo
                    @change="changeState"
                  />
                </div>
              </v-col>
              <v-col v-if="state" class="pa-0">
                <div class="main__content__left__dropdown">
                  <span class="dark-cyanBlue base-family">Area code</span>
                  <v-select
                    v-model="areaCode"
                    :items="areaCodeOptions"
                    :loading="codeLoading"
                    :disabled="!state || codeLoading"
                    item-text="label"
                    item-value="value"
                    label="Choose code"
                    dense
                    solo
                    @change="getNumbersList"
                  />
                </div>
              </v-col>
            </div>
          </v-col>
        </div>
        <div v-if="areaCode" class="mt-6 elevation-1">
          <v-data-table
            v-model="selectedNumbers"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="numbersLoading"
            :show-select="!isFreeAccount || isFreeNumber"
            hide-default-footer
            item-key="tn"
          >
            <template v-slot:[`header.count`]>
              <div v-if="!isFreeAccount || isFreeNumber" class="d-flex align-center justify-end duration-wrapper">
                <v-btn
                  :loading="loadingPrice"
                  :class="{ primary: durationType === 'month' }"
                  class="select-free"
                  height="36px"
                  @click="changeDurationType('month')"
                >
                  <span>Month</span>
                </v-btn>
                <v-btn
                  :loading="loadingPrice"
                  :class="{ primary: durationType === 'year' }"
                  class="select-free"
                  height="36px"
                  @click="changeDurationType('year')"
                >
                  <span>Annual</span>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <div class="d-flex align-center justify-end">
                <v-btn
                  v-if="isFreeAccount && !isFreeNumber"
                  class="select-free primary"
                  height="36px"
                  @click="buyFreeNumber(item)"
                >
                  <span>Select</span>
                </v-btn>
                <span v-else>{{ getPrice(item) }}</span>
              </div>
            </template>
          </v-data-table>
          <!-- <div v-if="items.length" class="pagination text-center justify-start px-2 py-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div> -->
        </div>
      </div>
      <div v-if="selectedNumbers.length" class="main__content__right bg-white pa-4 rounded">
        <checkout
          :is-free="isFreeAccount && !isFreeNumber"
          :selected-numbers="selectedNumbers"
          :total="totalAmount"
          :stripe-loading="paymentLoading"
          :number-type="numberType"
          :disabled="disableBuy"
          @changeDurationType="changeDurationType"
          @removeNumber="removeNumber"
          @onStripe="buyNumber('stripe')"
          @onFree="buyFreeNumber"
        />
      </div>
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import Checkout from '../components/Checkout.vue'
import { mapGetters, mapActions } from 'vuex'
const delay = ms => new Promise((resolve) => setTimeout(resolve, ms))

export default {
  name: 'BuyANewNumber',
  components: { Checkout },
  data () {
    return {
      country: 'US',
      state: '',
      stateOptions: [],
      areaCode: 0,
      areaCodeOptions: [],
      codeLoading: false,
      numberType: 'long_code',
      selectedNumbers: [],
      numbersLoading: false,
      page: 1,
      pageCount: 10,
      headers: [
        {
          text: 'All numbers',
          align: 'start',
          value: 'tn'
        },
        {
          text: 'phone numbers',
          value: 'count',
          align: 'end',
          sortable: false
        }
      ],
      items: [],
      tollFreeOptions: [
        {
          value: 800,
          label: 800
        },
        {
          value: 888,
          label: 888
        },
        {
          value: 877,
          label: 877
        },
        {
          value: 866,
          label: 866
        },
        {
          value: 855,
          label: 855
        },
        {
          value: 844,
          label: 844
        },
        {
          value: 833,
          label: 833
        }
      ],
      disableBuy: false,
      features: ['sms', 'voice'],
      durationType: 'month',
      totalAmount: 0,
      numberPrices: [],
      paymentLoading: false,
      loadingPrice: false,
      isFreeNumber: false
    }
  },
  computed: {
    ...mapGetters(['currentCompany', 'didCount']),
    isFreeAccount () {
      return this.currentCompany?.is_free && this.state === 'NY' && !this.didCount
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  async created () {
    await this.getAccountData(this.$store.state.auth.currentUser.role)
    if (this.currentCompany?.is_free) {
      this.countDIDs()
      this.state = 'NY'
      this.areaCode = 838
    }
    this.getStatesHandler()
    this.getAreaCodesHandler(this.state)

    this.getNumbersList()
  },
  watch: {
    selectedNumbers (val) {
      this.getTotalAmout()
    }
  },
  methods: {
    ...mapActions([
      'fetchNumbersList',
      'fetchAvailableNumbersList',
      'getAvailableStates',
      'getStates',
      'getAreaCodes',
      'orderNewNumber',
      'buyNewNumber',
      'assignFreeNumber',
      'fetchTotalAmount',
      'getAccountData',
      'didNumbersList'
    ]),
    async countDIDs () {
      try {
        await this.didNumbersList({})
        // if (!this.didCount) this.numberType = 'long_code'
        /* else {
          this.$snotify.notifications = []
          this.$snotify.error('Free user can have only 1 free number.')
          setTimeout(() => {
            this.$router.push({ name: 'PhoneNumbers' })
          }, 2000)
        } */
      } catch (err) {
        throw new Error(err)
      }
    },
    async getStatesHandler () {
      try {
        const stateHandler = this.currentCompany?.is_free ? 'getAvailableStates' : 'getStates'
        const { data } = await this[stateHandler]() // { country: this.country }
        const options = this.currentCompany?.is_free ? data : data.payload
        this.stateOptions = options.map(item => {
          const state = this.currentCompany?.is_free ? item.state : item
          return {
            value: state,
            label: state
          }
        }).sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1
          }
          return 0
        })
        // if (this.isFreeAccount) this.stateOptions.unshift({ label: 'Select', value: '' })
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      }
    },
    async getAreaCodesHandler (state) {
      if (!state) return
      try {
        const areaCodeOptions = []
        this.codeLoading = true

        const { data } = await this.getAreaCodes(state)

        data.forEach((item) => {
          item.npa.forEach(code => {
            if (!areaCodeOptions.includes(code)) {
              areaCodeOptions.push(code)
            }
          })
        })

        this.areaCodeOptions = areaCodeOptions.map(code => ({
          value: code,
          label: code
        }))
        // if (this.isFreeAccount) this.areaCodeOptions.unshift({ label: 'Select', value: 0 })
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.codeLoading = false
      }
    },
    async getNumbersList (clear = true) {
      try {
        if (!this.state || !this.areaCode) return
        if (clear) this.items = []
        this.emptySelected()
        this.numbersLoading = true

        const params = {
          type: 'long_code',
          state: this.state,
          npa: this.areaCode,
          is_free: this.isFreeAccount,
          take: this.pageCount
        }

        const { data } = await this.fetchAvailableNumbersList(params)
        if (params.state !== this.state) return
        const items = data.map(item => ({ tn: `${item}` }))
        this.items.push(...items)
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.numbersLoading = false
      }
    },
    changeState () {
      this.areaCode = 0
      this.getAreaCodesHandler(this.state)

      this.getNumbersList()
    },
    removeNumber (tn) {
      this.selectedNumbers = this.selectedNumbers.filter(item => item.tn !== tn)
    },
    changeDurationType (val) {
      if (this.durationType === val) return
      this.durationType = val

      this.getTotalAmout()
    },
    async getTotalAmout () {
      try {
        if (this.isFreeAccount && !this.isFreeNumber) return
        if (!this.selectedNumbers.length) {
          this.totalAmount = 0
          return
        }
        this.loadingPrice = true
        const payload = {
          durationUnit: this.durationType,
          numbers: this.selectedNumbers.map(item => ({
            didNumber: item.tn.toString(),
            // numberType: this.numberType,
            features: this.features
          }))
        }

        const data = await this.fetchTotalAmount(payload)

        this.totalAmount = data.totalAmount
        this.numberPrices = data.didNumbers
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.loadingPrice = false
      }
    },
    getPrice (tn) {
      const number = this.numberPrices.find(e => e.didNumber === tn.tn)
      return number ? `$ ${number.amount}` : ''
    },
    async buyFreeNumber (number) {
      this.$snotify.notifications = []
      try {
        if (!number) return

        this.paymentLoading = true

        await this.countDIDs()
        if (this.didCount) return this.$snotify.error('Free user can have only 1 free number.')

        const { data } = await this.assignFreeNumber({
          didNumber: number.tn.toString(),
          autorenew: true
        })

        if (!data || !data.success) return
        this.disableBuy = true

        const options = {
          timeout: 0,
          buttons: [
            {
              text: 'Phone Numbers',
              action: (toast) => {
                this.$router.push({ name: 'PhoneNumbers', params: { reload: true } })
                this.$snotify.remove(toast.id)
              }
            }
          ]
        }
        this.$snotify.success('Your Number Order is Submitted.', options)
        setTimeout(() => {
          this.$router.push({ name: 'PhoneNumbers', params: { reload: true } })
        }, 4000)
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.paymentLoading = false
      }
    },
    async buyNumber (type) {
      this.$snotify.notifications = []
      try {
        if (!this.selectedNumbers.length) {
          this.$snotify.error('Please select the number')
          return
        }

        this.paymentLoading = true

        const didNumbers = this.selectedNumbers.map(item => ({
          didNumber: item.tn.toString(),
          features: this.features,
          autorenew: true
        }))

        const { status, data } = await this.orderNewNumber({
          didNumbers,
          durationUnit: this.durationType
        })

        if (status === 202) {
          window.location.replace(data.url)
        }
        let response = null

        if (status === 201 || status === 200) {
          response = await this.waitForPaymentId(data.order.orderUuid, type)
        }

        if (response) {
          window.location.replace(response.data.paymentUrl)
        }
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.paymentLoading = false
      }
    },
    async waitForPaymentId (orderUuid, type) {
      try {
        const payload = {
          paymentType: type,
          orderUuid: orderUuid,
          durationUnit: this.durationType
        }

        const response = await this.buyNewNumber(payload)

        if (response.data.status === 'waiting') {
          await delay(10000)
          return this.waitForPaymentId(orderUuid, type)
        }

        return response
      } catch (e) {
        console.log(e, 'eeeeeeeeeeeeeee')
        if (e.status === 'waiting') {
          await delay(10000)
          return this.waitForPaymentId(orderUuid, type)
        } else {
          return false
        }
      }
    },
    tollFreeHandler () {
      this.areaCodeOptions = this.tollFreeOptions

      this.areaCode = this.areaCodeOptions[0].value

      this.getNumbersList()
    },
    longCodeHandler () {
      this.getAreaCodesHandler(this.state)
      this.areaCode = 838

      this.getNumbersList()
    },
    /* changeNumberType (e) {
      this.emptySelected()

      if (e === 'is_free') {
        this.$snotify.notifications = []
        if (!this.isFreeAccount) return this.$snotify.error('Paid user can not have free number.')
        else if (this.didCount) return this.$snotify.error('Free user can have only 1 free number.')
      }
      this.numberType = e
      this.longCodeHandler()
    }, */
    emptySelected () {
      this.selectedNumbers = []
    }
  }
}
</script>

<style scoped lang="scss">
.base-family {
  font-family: 'SF Pro Display' !important;
}

.main {
  max-width: 1065px;
  margin: 0 auto;
  width: 100%;

  &__content {
    padding-inline: 10px;
  }

  &__content__left {
    flex: 70%;

    @include breakpoint(small) {
      flex: 100%;
    }

    &>div:first-child {
      box-shadow: 0px 1px 1px 0 #00000010;
      border: 0.8px solid #E9ECEF;
    }
  }

  &__content__right {
    flex: 30%;
    align-self: baseline;
    position: relative;
    box-shadow: 0px 1px 1px 0 #00000010;
    border: 0.8px solid #E9ECEF;
    font-family: 'SF Pro Display' !important;

    @include breakpoint(small) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .pagination {
    background-color: #fff;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-pagination {
    justify-content: start !important;
    position: relative;

    li {
      &:first-of-type {
        position: absolute;
        right: 35px;

        @include breakpoint(small) {
          display: none;
        }
      }

      &:last-of-type {
        position: absolute;
        right: 0;

        @include breakpoint(small) {
          display: none;
        }
      }
    }
  }

  .v-pagination__item {
    width: 54px !important;
    height: 34px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%) !important;
    border: 0.8px solid #CED4DA !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
    color: #495057 !important;
    margin: 0 !important;
    border-radius: 0;

    &--active {
      border: 0.8px solid #CED4DA !important;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
      background: #F4F6F8 !important;
    }
  }

  .v-pagination__navigation {
    width: 36px !important;
    height: 34px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%) !important;
    border: 0.8px solid #CED4DA !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
    border-radius: 0;

    .v-icon.v-icon {
      font-size: 16px !important;
    }
  }

  .mdi-chevron-left::before {
    content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.75 6C17.75 5.65482 17.4702 5.375 17.125 5.375H2.38388L6.31694 1.44194C6.56102 1.19787 6.56102 0.802136 6.31694 0.558059C6.07286 0.31398 5.67714 0.31398 5.43306 0.558059L0.433058 5.55806C0.188981 5.80214 0.188981 6.19787 0.433058 6.44194L5.43306 11.4419C5.67714 11.686 6.07286 11.686 6.31694 11.4419C6.56102 11.1979 6.56102 10.8021 6.31694 10.5581L2.38388 6.625H17.125C17.4702 6.625 17.75 6.34518 17.75 6Z' fill='%23667280'/%3E%3C/svg%3E%0A");
    ;
  }

  .mdi-chevron-right::before {
    content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.25 6C0.25 5.65482 0.529822 5.375 0.875 5.375H15.6161L11.6831 1.44194C11.439 1.19787 11.439 0.802136 11.6831 0.558059C11.9271 0.31398 12.3229 0.31398 12.5669 0.558059L17.5669 5.55806C17.811 5.80214 17.811 6.19787 17.5669 6.44194L12.5669 11.4419C12.3229 11.686 11.9271 11.686 11.6831 11.4419C11.439 11.1979 11.439 10.8021 11.6831 10.5581L15.6161 6.625H0.875C0.529822 6.625 0.25 6.34518 0.25 6Z' fill='%23667280'/%3E%3C/svg%3E%0A");
  }

  .v-input__slot[aria-haspopup=listbox] {
    border: 0.8px solid #CED4DA;
    border-radius: 3px;
    box-shadow: none !important;
  }

  .v-icon.v-icon {
    font-size: 22px !important;
    color: #CED4DA;
  }

  .mdi-checkbox-marked {
    color: #5c6ac4 !important;
  }

  .mdi-minus-box {
    color: #5c6ac4 !important;
  }

  .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-block: 10px;
    .radio {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      .radio-btn {
        width: 20px;
        aspect-ratio: 1;
        border: 1px solid #5c6ac4;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }
      &.is-checked {
        .radio-btn {
          &::after {
            content: '';
            width: 10px;
            aspect-ratio: 1;
            background: #5c6ac4;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

::v-deep .v-label {
  color: #C7C7C7 !important;
}

::v-deep .text-start.sortable {
  span {
    margin-right: 10px;
  }
  i {
    font-size: 16px !important;
  }
}

.duration-wrapper {
  gap: 10px;
  ::v-deep button {
    height: 30px !important;
  }
}
</style>
